import React, { FC, useState } from 'react'
import CN from 'classnames'

import { Modal } from '../Modals'

import { CustomizeColumns } from './CustomizeColumns'

export interface CustomizeColumModalModalProps {
  [x: string]: any
  section?: string
  isModalOpen: boolean
  handleCloseModal: () => void
  localStorageKey?: string
}

export const CustomizeColumModal: FC<CustomizeColumModalModalProps> = ({
  className,
  columns,
  handleCloseModal,
  handleCustomizeCol,
  resetCustomizesColumns,
  isModalOpen,
  section,
  localStorageKey,
  ...restProps
}: CustomizeColumModalModalProps) => {
  const ModalClasses = CN('w-[620px]', className)

  const [columnList, setColumnList] = useState(columns)

  const onChange = (columnList: any) => {
    setColumnList(columnList)
  }

  return (
    <Modal
      isActive={isModalOpen}
      className={ModalClasses}
      isCustomFooter={true}
      headerTitle='Customize Columns'
      primaryButtonTitle='Save'
      secondaryButtonTitle='Cancel'
      customFooterButtonTitle='RESET FILTER'
      onOverlayClick={() => {
        handleCloseModal()
      }}
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickPrimaryBtn={() => {
        handleCustomizeCol(columnList)
        handleCloseModal()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}
      onClickCustomFooterBtn={() => {
        resetCustomizesColumns()
        handleCloseModal()
      }}
      {...restProps}>
      <div className='flex w-full p-2 h-full'>
        <CustomizeColumns
          columns={columns}
          onChange={onChange}
          section={section}
          localStorageKey={localStorageKey}
        />
      </div>
    </Modal>
  )
}

CustomizeColumModal.defaultProps = {}

export default CustomizeColumModal
