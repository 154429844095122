/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios'
import AuthRefreshInterceptor from 'axios-auth-refresh'
import { createBrowserHistory } from 'history'

import { apiBaseUrl } from 'config/framework.config'

/* Initialize Axios as http */
export const http = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
})

export const interceptedHttp = http.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

const refreshAuthLogic = (failedRequest: any) => {
  const history = createBrowserHistory()

  const currentUrl = history.location.pathname

  if (currentUrl === '/auth/login') {
    return Promise.resolve()
  }

  const refreshToken = localStorage.getItem('refreshToken')

  if (refreshToken) {
    return axios
      .post(`${apiBaseUrl}identity/token`, { refreshToken })
      .then((tokenRefreshResponse) => {
        localStorage.setItem('token', tokenRefreshResponse.data.token)
        localStorage.setItem(
          'refreshToken',
          tokenRefreshResponse.data.refreshToken,
        )

        failedRequest.response.config.headers['Authorization'] =
          'Bearer ' + tokenRefreshResponse.data.token
        return Promise.resolve()
      })
      .catch(() => {
        localStorage.clear()
        history.replace('/auth/login')
        window.location.reload()
      })
  }
  localStorage.clear()

  history.replace('/auth/login')
  window.location.reload()

  return Promise.reject()
}

export const refreshInterceptor = AuthRefreshInterceptor(
  http,
  refreshAuthLogic,
  {
    interceptNetworkError: true,
  },
)

/* Get Data */
export const get = (url: string, options: any = null) => http.get(url, options)

/* Post Data */
export const post = (url: string, data: any, options: any = null) =>
  http.post(url, data, options)

/* PUT Data */
export const put = (url: string, data: any, options: any = null) =>
  http.put(url, data, options)

/* Fetch API */
export const fetchAPI = async (url: string, options: any = null) => {
  const { status, statusText, data } = await http.get(url, options)
  return { status, statusText, data }
}

/* Post API */
export const postAPI = async (
  url: string,
  payload: any,
  options: any = null,
) => {
  return http.post(url, payload, options)
}

/* Post API */
export const putAPI = async (
  url: string,
  payload: any,
  options: any = null,
) => {
  return http.put(url, payload, options)
}

/* Delete API */
export const deleteAPI = async (url: string, options: any = null) => {
  const { status, statusText, data } = await http.delete(url, options)
  return { status, statusText, data }
}

/* Export axios as default */
export default http
