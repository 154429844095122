import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateUserDetailsInterface {
  id: string
  fullName: string
  phoneNumber: string
  supportEmail: string
  streetAddress: string
  suburb: string
  state: any
  country: any
  postalCode: string
}

export const useUpdateUserDetails = () => {
  const mutate = useMutation(
    ({
      id,
      fullName,
      phoneNumber,
      supportEmail,
      streetAddress,
      suburb,
      state,
      country,
      postalCode,
    }: UpdateUserDetailsInterface) => {
      return putAPI(`/ClientProfile`, {
        id: id,
        phoneNumber: phoneNumber,
        fullName: fullName,
        supportEmail: supportEmail,
        address: {
          lineOne: streetAddress || '',
          lineTwo: '',
          city: '',
          suburb: suburb || '',
          state: state?.label,
          stateCode: state?.value,
          country: country?.label,
          countryCode: country?.value,
          postCode: postalCode || '',
        },
      })
    },
  )

  return mutate
}

export default useUpdateUserDetails
