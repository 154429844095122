/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import CN from 'classnames'
import {
  AUSPOST,
  AUSPOSTINTERNATIONAL,
  DIRECT,
  SMARTSEND,
} from 'static-data/courierList'

import { courierNameConversion } from 'utils'

export interface OrderInformationSectionProps {
  [x: string]: any
  className?: string | undefined
}

export const OrderInformationSection: FC<OrderInformationSectionProps> = ({
  className,
  orderDetails,
  shippingInformation,
  warehouseDetails,
  ...restProps
}: OrderInformationSectionProps) => {
  const OrderStatusProgressBarClassnames = CN(
    'rounded border-[1px] border-N-200 mt-8 p-3',
    className,
  )

  return (
    <div className={OrderStatusProgressBarClassnames} {...restProps}>
      <span className='font-semibold text-heading-3 text-N-800'>
        Order Informations
      </span>

      <div className='border-[1px] border-P-200 bg-P-50 rounded mt-6'>
        <span className='font-medium text-base text-n-600 pl-2 py-1'>
          Receiver Information :
        </span>
      </div>

      <div className='grid grid-cols-3 pt-4 gap-y-6'>
        <div className='flex flex-col'>
          <span className='font-regular text-base text-N-500'>Full Name</span>

          <span className='font-medium text-base text-N-800 pt-1'>
            {orderDetails?.customerFirstName +
              ' ' +
              orderDetails?.customerLastName}
          </span>
        </div>

        <div className='flex flex-col'>
          <span className='font-regular text-base text-N-500'>
            Email Address
          </span>
          <a
            href={
              orderDetails?.customerEmail
                ? `mailto:${orderDetails?.customerEmail}`
                : undefined
            }
            className='font-medium text-base text-N-800 pt-1'>
            {orderDetails?.customerEmail ? orderDetails?.customerEmail : 'N/A'}
          </a>
        </div>

        <div className='flex flex-col'>
          <span className='font-regular text-base text-N-500'>
            Phone Number
          </span>
          <span className='font-medium text-base text-N-800 pt-1'>
            {orderDetails?.customerPhone ? orderDetails?.customerPhone : 'N/A'}
          </span>
        </div>

        <div className='flex flex-col break-words'>
          <span className='font-regular text-base text-N-500'>Address</span>

          <span className='font-medium text-base text-N-800 pt-1 pr-2 break-words'>
            {orderDetails?.shippingAddress?.lineOne &&
              `${orderDetails?.shippingAddress?.lineOne}, `}

            {orderDetails?.shippingAddress?.lineTwo &&
              `${orderDetails?.shippingAddress?.lineTwo}, `}

            {orderDetails?.shippingAddress?.suburb &&
              `${orderDetails?.shippingAddress?.suburb}, `}

            {orderDetails?.shippingAddress?.state &&
              `${orderDetails?.shippingAddress?.state}, `}

            {orderDetails?.shippingAddress?.postCode &&
              `${orderDetails?.shippingAddress?.postCode}, `}

            {orderDetails?.shippingAddress?.country &&
              `${orderDetails?.shippingAddress?.country}`}
          </span>
        </div>
      </div>

      <div className='border-[1px] border-P-200 bg-P-50 rounded mt-6'>
        <span className='font-medium text-base text-n-600 pl-2 py-1'>
          Dispatched From :
        </span>
      </div>

      <div className='grid grid-cols-3 pt-4 gap-y-6'>
        <div className='flex flex-col'>
          <span className='font-regular text-base text-N-500'>
            Warehouse Code
          </span>

          <span className='font-medium text-base text-N-800 pt-1'>
            {warehouseDetails?.code ? warehouseDetails?.code : 'N/A'}
          </span>
        </div>

        <div className='flex flex-col'>
          <span className='font-regular text-base text-N-500'>
            Warehouse Name
          </span>

          <span className='font-medium text-base text-N-800 pt-1'>
            {warehouseDetails?.name ? warehouseDetails?.name : 'N/A'}
          </span>
        </div>

        <div className='flex flex-col break-words'>
          <span className='font-regular text-base text-N-500'>
            Warehouse Email
          </span>
          <a
            href={
              warehouseDetails?.email
                ? `mailto:${warehouseDetails?.email}`
                : undefined
            }
            className='font-medium text-base text-N-800 pt-1'>
            {warehouseDetails?.email ? warehouseDetails?.email : 'N/A'}
          </a>
        </div>

        <div className='flex flex-col break-words'>
          <span className='font-regular text-base text-N-500'>
            Warehouse Address
          </span>

          <span className='font-medium text-base text-N-800 pt-1 pr-2 break-words'>
            {warehouseDetails?.address?.lineOne &&
              `${warehouseDetails?.address?.lineOne}, `}

            {warehouseDetails?.address?.suburb &&
              `${warehouseDetails?.address?.suburb}, `}

            {warehouseDetails?.address?.state &&
              `${warehouseDetails?.address?.state}, `}

            {warehouseDetails?.address?.postCode &&
              `${warehouseDetails?.address?.postCode}, `}

            {warehouseDetails?.address?.country &&
              `${warehouseDetails?.address?.country}`}
          </span>
        </div>

        <div className='flex flex-col'>
          <span className='font-regular text-base text-N-500'>
            Warehouse Phone
          </span>
          <span className='font-medium text-base text-N-800 pt-1'>
            {warehouseDetails?.phoneNo ? warehouseDetails?.phoneNo : 'N/A'}
          </span>
        </div>
      </div>
      <div className='border-[1px] border-P-200 bg-P-50 rounded mt-6'>
        <span className='font-medium text-base text-n-600 pl-2 py-1'>
          Shipping Information :
        </span>
      </div>

      {shippingInformation?.length === 0 && (
        <div className='grid grid-cols-3 pt-4'>
          <div className='flex flex-col'>
            <span className='font-regular text-base text-N-500'>Courier</span>

            <span className='font-medium text-base text-N-800 pt-1'>N/A</span>
          </div>

          <div className='flex flex-col'>
            <span className='font-regular text-base text-N-500'>
              Courier Service
            </span>

            <span className='font-medium text-base text-N-800 pt-1'>N/A</span>
          </div>

          <div className='flex flex-col'>
            <span className='font-regular text-base text-N-500 '>
              Tracking Code
            </span>

            <span className={CN('font-medium text-base  pt-1 !text-N-800')}>
              N/A
            </span>
          </div>
        </div>
      )}

      {shippingInformation?.length > 0 &&
        shippingInformation?.map((item: any, index: number) => (
          <div className='flex flex-col' key={index}>
            <div className='grid grid-cols-3 pt-4'>
              <div className='flex flex-col'>
                <span className='font-regular text-base text-N-500'>
                  Courier
                </span>

                <span className='font-medium text-base text-N-800 pt-1'>
                  {item?.type ? courierNameConversion(item?.type) : 'N/A'}
                </span>
              </div>

              <div className='flex flex-col'>
                <span className='font-regular text-base text-N-500'>
                  Courier Service
                </span>

                <span className='font-medium text-base text-N-800 pt-1'>
                  {item?.productName ? item?.productName : 'N/A'}
                </span>
              </div>

              <div className='flex flex-col'>
                <span className='font-regular text-base text-N-500 '>
                  Tracking Code
                </span>

                <span
                  className={CN(
                    'font-medium text-base !text-P-500 pt-1',
                    {
                      'cursor-pointer hover:!text-P-400':
                        item?.type === AUSPOST ||
                        item?.type === AUSPOSTINTERNATIONAL ||
                        item?.type === DIRECT ||
                        item?.type === SMARTSEND,
                    },
                    {
                      '!text-N-800': !item?.trackingCode,
                    },
                  )}>
                  {item?.trackingCode
                    ? item?.shippmentConsignmentItems?.map(
                        (itm: any, index: any) => (
                          <div className='flex flex-col'>
                            <span
                              onClick={() => {
                                if (
                                  item?.type === AUSPOST ||
                                  item?.type === AUSPOSTINTERNATIONAL
                                ) {
                                  window.open(
                                    `https://auspost.com.au/mypost/track/#/details/${itm?.trackingCode}`,
                                    '_blank',
                                  )
                                } else if (item?.type === DIRECT) {
                                  window.open(
                                    `https://www.directfreight.com.au/ConsignmentStatus.aspx?lookuptype=0&consignment_no=${itm?.trackingCode}`,
                                    '_blank',
                                  )
                                } else if (item?.type === SMARTSEND) {
                                  window.open(
                                    `https://www.smartsend.com.au/tracking.cfm`,
                                    '_blank',
                                  )
                                }
                              }}>
                              {itm?.trackingCode}
                            </span>
                          </div>
                        ),
                      )
                    : 'N/A'}
                </span>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

OrderInformationSection.defaultProps = {
  className: undefined,
  currentStatus: undefined,
}

export default OrderInformationSection
