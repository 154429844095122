export const OrderLineListColumn = () => {
  const COLUMNS = [
    {
      Header: 'PARTNER PRODUCT',
      accessor: 'partnerSKU',
      forSorting: 'partnerSKU',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[212px] pl-6',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='w-full cursor-pointer'>{original.partnerSKU}</div>
        )
      },
    },
    {
      Header: 'ITEM',
      accessor: 'name',
      forSorting: 'name',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[112px] pl-6',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.name ? original?.name : 'N/A'}</div>
      },
    },
    {
      Header: 'QTY',
      accessor: 'qty',
      forSorting: 'qty',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.qty ? original?.qty : 'N/A'}</div>
      },
    },
    {
      Header: 'WAREHOUSE PRODUCT',
      accessor: 'warehouseSKU',
      forSorting: 'warehouseSKU',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.warehouseSKU}</div>
      },
    },
    {
      Header: 'WAREHOUSE NAME',
      accessor: 'warehouseCode',
      forSorting: 'warehouseCode',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[70px] pl-6',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>{original?.warehouseCode ? original?.warehouseCode : 'N/A'}</div>
        )
      },
    },
  ]

  return COLUMNS
}

export default OrderLineListColumn
