import { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetPartnerOrderIntegrations,
  useGetPartnerOrders,
  useOrderReportExportAsCSV,
  useOrderReportExportAsExcel,
} from 'framework/api/methods'
import {
  BACKORDER,
  CANCELLED,
  CLOSED,
  CONFIRMED,
  EXCEPTION,
  FAILED,
  ONHOLD,
  OPEN,
  PARTIALCONSIGNED,
  RECEIVED,
  TOBECONSIGNED,
} from 'static-data/orderStatus'

import { Button, DateTimeRangePicker, Dropdown } from 'components/atoms'
import { TableHeader, UITable } from 'components/common/UITable'
import { Toast } from 'components/molecules'
import CustomizeColumModal from 'components/molecules/CustomizeColumModal/CustomizeColumModal'
import { dateAndTimeIntoISTFormat, orderStatusNameConversion } from 'utils'

import { OrderListColumn } from './OrderListColumn'

export interface OrderListProps {
  className?: string | undefined
  [x: string]: any
}

export const OrderList: FC<OrderListProps> = ({ children }: OrderListProps) => {
  const orderStatusList = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: orderStatusNameConversion(CANCELLED),
      value: CANCELLED,
    },
    {
      label: orderStatusNameConversion(CLOSED),
      value: CLOSED,
    },
    {
      label: orderStatusNameConversion(CONFIRMED),
      value: CONFIRMED,
    },
    {
      label: orderStatusNameConversion(EXCEPTION),
      value: EXCEPTION,
    },
    {
      label: orderStatusNameConversion(FAILED),
      value: FAILED,
    },
    {
      label: orderStatusNameConversion(ONHOLD),
      value: ONHOLD,
    },
    {
      label: orderStatusNameConversion(PARTIALCONSIGNED),
      value: PARTIALCONSIGNED,
    },
    {
      label: orderStatusNameConversion(TOBECONSIGNED),
      value: TOBECONSIGNED,
    },
    {
      label: orderStatusNameConversion(OPEN),
      value: OPEN,
    },
    {
      label: orderStatusNameConversion(RECEIVED),
      value: RECEIVED,
    },
  ]

  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const date = new Date()
  const startDateValue = new Date(date.getFullYear(), date.getMonth() - 1, 1)
  const endDateValue = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1,
  )

  const notify = (props: any) => Toast(props)
  const navigate = useNavigate()

  const [csvOrderData, setCsvOrderData] = useState<any>([])
  const [isOpenCustomizeModal, setIsOpenCustomizeModal] = useState(false)
  const [tableData, setTableData] = useState<any>()
  const [orderTableColumn, setOrderTableColumn] = useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData?.skip || 0,
    take: tableData?.take || 10,
    search: '',
  })
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(1)
  const [startValue, setStartValue] = useState<any>(startDateValue)
  const [endValue, setEndValue] = useState<any>(endDateValue)
  const [selectedRowsIds, setSelectedRowsIds] = useState<any>([])
  const [selectedIntegrations, setSelectedIntegrations] = useState<any>({
    label: 'All',
    value: 'All',
  })
  const [selectedStatus, setSelectedStatus] = useState<any>({
    label: 'All',
    value: 'All',
  })
  const [selectedOriginalRowsIds, setSelectedOriginalRowsIds] = useState<any>(
    [],
  )

  const [isDateTimeFieldError, setIsDateTimeFieldError] = useState(false)

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [integrationList, setIntegrationList] = useState([])
  const [isLoadingCSVDownload, setIsLoadingCSVDownload] = useState(false)
  const [selectedRowIndexes, setSelectedRowIndexes] = useState<any>([])
  const [selectedAllIdList, setSelectedAllIdList] = useState<any>([])
  const [deselectIds, setDeselectIds] = useState<any>()

  useEffect(() => {
    getPartnerOrderIntegrations()
  }, [])

  useEffect(() => {
    getPartnerOrders()
  }, [paginationVariables])

  const { mutate: getPartnerOrderIntegrationsMutate } =
    useGetPartnerOrderIntegrations()

  /** Process the Get partner order integration */
  async function getPartnerOrderIntegrations() {
    getPartnerOrderIntegrationsMutate(
      { id: 1 },
      {
        onSuccess: ({ data: successData }: any) => {
          const integrations = successData.intergrations.map((int: any) => {
            return {
              value: int,
              label: int,
            }
          })
          setIntegrationList(integrations)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData.message
              ? errData.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const temp = JSON.parse(localStorage.getItem('orderTableColumn') as any)

  /** Cart Table Column Handling */
  useEffect(() => {
    if (tableData?.partnerOrders && tableData?.partnerOrders?.length > 0) {
      const columns = OrderListColumn(navigate)
      if (temp.length === 0) {
        setOrderTableColumn(columns)
      } else {
        const tempCol = temp?.map((item: any) => {
          const tempItem = columns?.find((col: any) => col.id === item.id)
          return {
            ...item,
            Cell: tempItem?.Cell,
          }
        })
        setOrderTableColumn(tempCol)
      }
    }
  }, [tableData])

  /** API Call For Partner Orders (Order List View)  */
  const {
    mutate: getPartnerOrdersMutate,
    isLoading: getPartnerOrdersIsLoading,
  } = useGetPartnerOrders()

  /** Process the get Order Table Data */
  async function getPartnerOrders() {
    getPartnerOrdersMutate(
      {
        take: paginationVariables?.take,
        skip: paginationVariables?.skip,
        search: paginationVariables?.search,
        status: selectedStatus?.value,
        integration: selectedIntegrations.value,
        from: dateAndTimeIntoISTFormat(startValue),
        to: dateAndTimeIntoISTFormat(endValue),
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setTableData(successData)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const resetCustomizesColumns = () => {
    setOrderTableColumn(OrderListColumn(navigate))
  }

  useEffect(() => {
    const columns = OrderListColumn(navigate)

    const storedOrderTableColumn = JSON.parse(
      localStorage.getItem('orderTableColumn') as any,
    )
    if (storedOrderTableColumn === null) {
      localStorage.setItem(
        'orderTableColumn',
        JSON.stringify(OrderListColumn(navigate)),
      )
    } else {
      const tempCol = storedOrderTableColumn?.map((item: any) => {
        const tempItem = columns?.find((col: any) => col.id === item.id)
        return {
          ...item,
          Cell: tempItem?.Cell,
        }
      })
      setOrderTableColumn(tempCol)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('orderTableColumn', JSON.stringify(orderTableColumn))
  }, [orderTableColumn])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setPaginationVariables({
        ...paginationVariables,
        skip: 0,
        search: searchKeyWord,
      })
      setPageNumber(1)
    }
  }

  const resetFilter = () => {
    setSelectedRowsIds([])
    setSelectedOriginalRowsIds([])
    setSelectedRowIndexes([])
    setDeselectIds(null)
    setSelectedAllIdList([])

    setIsDateTimeFieldError(false)
    setStartValue(startDateValue)
    setEndValue(endDateValue)
    setFilterActive(false)
    setSelectedStatus({ label: 'All', value: 'All' })
    setSelectedIntegrations({ label: 'All', value: 'All' })
    setPaginationVariables({
      ...paginationVariables,
      skip: 0,
      search: '',
    })
    setPageNumber(1)
    setIsFilterMenuOpen(false)
  }

  useEffect(() => {
    setSelectedRowsIds([])
    setSelectedOriginalRowsIds([])
    setSelectedRowIndexes([])
    setDeselectIds(null)
  }, [pageNumber])

  useEffect(() => {
    const tempId = selectedRowsIds?.map((item: any) => item?.id)
    setSelectedOriginalRowsIds(tempId)
  }, [selectedRowsIds])

  /** API Call export csv using react-query */
  const { mutate: orderReportExportAsCSVMutate } = useOrderReportExportAsCSV()
  /** Process the export csv*/
  async function orderReportExportAsCSV() {
    setIsLoadingCSVDownload(true)
    orderReportExportAsCSVMutate(
      {
        searchKeyword: paginationVariables?.search,
        status: selectedStatus?.value,
        integration: selectedIntegrations.value,
        partnerOrderId:
          selectedAllIdList?.length > 0 ? selectedAllIdList : null,
        from: dateAndTimeIntoISTFormat(startValue),
        to: dateAndTimeIntoISTFormat(endValue),
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCsvOrderData(successData)

          setTimeout(() => {
            csvLinkEl?.current?.link.click()
            setIsLoadingCSVDownload(false)
            notify({
              alertHeader: 'Success',
              alertBody: 'CSV file downloaded successfully',
              status: 'Success',
            })
          }, 3000)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const onSuccessExport = () => {
    notify({
      alertHeader: 'Success',
      alertBody: 'Excel file downloaded successfully',
      status: 'Success',
    })
  }

  const onErrorExport = () => {
    notify({
      alertHeader: 'Error',
      alertBody: 'Something went wrong',
      status: 'Error',
    })
  }

  /** order list report Export excel API */
  const {
    refetch: refetchExcel,
    isRefetching: isRefetchingExcelDownload,
    isLoading: isLoadingExcelDownload,
  } = useOrderReportExportAsExcel(
    {
      searchKeyword: paginationVariables?.search,
      status: selectedStatus?.value === 'All' ? null : selectedStatus?.value,
      integration:
        selectedIntegrations.value === 'All'
          ? null
          : selectedIntegrations?.value,
      partnerOrderId: selectedAllIdList?.length > 0 ? selectedAllIdList : null,
      from: dateAndTimeIntoISTFormat(startValue) || null,
      to: dateAndTimeIntoISTFormat(endValue) || null,
    },
    onSuccessExport,
    onErrorExport,
  )

  useEffect(() => {
    if (deselectIds === 'All') {
      const matchingIds = tableData?.partnerOrders.reduce(
        (ids: any, item: any) => {
          ids.push(item.id)
          return ids
        },
        [],
      )

      setSelectedOriginalRowsIds(
        selectedOriginalRowsIds?.filter(
          (item: any) => !matchingIds?.includes(item),
        ),
      )
    }
  }, [deselectIds, paginationVariables?.skip])

  useEffect(() => {
    if (selectedOriginalRowsIds?.length > 0) {
      const temp = [...selectedAllIdList, ...selectedOriginalRowsIds]
      const uniqueSet: any = new Set(temp)
      const uniqueArray = [...uniqueSet]

      if (deselectIds) {
        if (deselectIds === 'All') {
          const matchingIds = tableData?.partnerOrders.reduce(
            (ids: any, item: any) => {
              ids.push(item.id)
              return ids
            },
            [],
          )
          setSelectedAllIdList(
            uniqueArray?.filter((item: any) => !matchingIds?.includes(item)),
          )
        } else {
          setSelectedAllIdList(
            uniqueArray?.filter((item: any) => item !== deselectIds),
          )
        }
      } else {
        setSelectedAllIdList(uniqueArray)
      }
    }
  }, [pageNumber, selectedOriginalRowsIds, deselectIds])

  useEffect(() => {
    if (tableData?.partnerOrders) {
      const matchingIndexes = tableData?.partnerOrders.reduce(
        (indexes: any, item: any, index: any) => {
          if (selectedAllIdList?.includes(item.id)) {
            indexes.push(index)
          }
          return indexes
        },
        [],
      )
      setSelectedRowIndexes(matchingIndexes)
    }
  }, [tableData?.partnerOrders])

  return (
    <>
      <div className='flex flex-col h-fit w-full bg-white rounded-lg px-3 py-6 shadow-md'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={
            'Search by SKU Code, Warehouse Order No, Customer Order No'
          }
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}
          handleSearchKeyPress={handleSearchKeyPress}>
          <div className='w-full flex justify-end gap-x-3 px-3 h-full'>
            <Button
              className='h-auto'
              appearance='secondary'
              iconBefore='ri-table-line text-[24px]'
              disabled={orderTableColumn?.length === 0}
              onClick={() => {
                setIsOpenCustomizeModal(true)
              }}>
              Customize Columns
            </Button>

            <Button
              className='h-auto'
              appearance='secondary'
              iconBefore='ri-file-download-line text-[24px]'
              disabled={orderTableColumn?.length === 0}
              onClick={() => {
                orderReportExportAsCSV()
              }}>
              Export CSV
            </Button>

            <Button
              className='h-auto'
              iconBefore='ri-file-excel-2-line text-[24px]'
              disabled={orderTableColumn?.length === 0}
              appearance='secondary'
              onClick={() => {
                refetchExcel()
              }}>
              Export Excel
            </Button>
          </div>
        </TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex flex-col bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.5 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: 0, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex flex-col w-full pt-3 z-[10] gap-y-4'>
                <div className='flex justify-start gap-x-3'>
                  <Dropdown
                    label='By Integration'
                    placeholder='All'
                    value={selectedIntegrations}
                    className='w-1/3'
                    onChange={(selectedItem: any) => {
                      setSelectedIntegrations({
                        value: selectedItem?.value,
                        label: selectedItem?.label,
                      })
                    }}
                    options={[
                      { label: 'All', value: 'All' },
                      { label: 'Manual', value: 'Manual' },
                      ...integrationList,
                    ]}
                  />

                  <Dropdown
                    label='By Status'
                    placeholder='All'
                    value={selectedStatus}
                    className='w-1/3'
                    onChange={(selectedItem: any) => {
                      setSelectedStatus({
                        value: selectedItem?.value,
                        label: selectedItem?.label,
                      })
                    }}
                    options={orderStatusList}
                  />

                  <div className='select-service-type-drop-down flex flex-col w-[38%]'>
                    <span className='text-xs font-medium text-N-700 pb-[3px]'>
                      Order Date
                    </span>

                    <DateTimeRangePicker
                      onStartChange={(e: any) => {
                        setStartValue(e.target.value)
                        setIsDateTimeFieldError(false)
                      }}
                      onEndChange={(e: any) => {
                        setEndValue(e.target.value)
                        setIsDateTimeFieldError(false)
                      }}
                      startValue={startValue}
                      endValue={endValue}
                      isErrored={isDateTimeFieldError}
                    />
                  </div>
                </div>

                <div className='flex justify-start gap-x-2'>
                  <Button
                    disabled={
                      startValue &&
                      startValue?.toString() == startDateValue.toString() &&
                      endValue &&
                      endValue?.toString() == endDateValue.toString() &&
                      !selectedStatus?.value &&
                      !selectedIntegrations?.value
                    }
                    onClick={() => {
                      if (startValue && endValue && startValue < endValue) {
                        getPartnerOrders()
                        setPageNumber(1)

                        setIsFilterMenuOpen(false)

                        setSelectedRowsIds([])
                        setSelectedOriginalRowsIds([])
                        setSelectedRowIndexes([])
                        setDeselectIds(null)
                        setSelectedAllIdList([])

                        if (
                          startValue?.toString() == startDateValue.toString() &&
                          endValue?.toString() == endDateValue.toString() &&
                          selectedIntegrations?.value === 'All' &&
                          selectedStatus?.value === 'All'
                        ) {
                          setFilterActive(false)
                        } else {
                          setFilterActive(true)
                        }
                      } else {
                        setIsDateTimeFieldError(true)
                      }
                    }}>
                    Apply Filter
                  </Button>

                  <Button
                    appearance='secondary'
                    onClick={() => {
                      resetFilter()
                    }}>
                    Reset
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className='w-full h-[calc(80vh-171px)] relative pt-8'>
          <div
            className={CN(
              'styled-scroll overflow-auto w-full h-[calc(100%-58px)] bg-white',
            )}>
            <UITable
              data={tableData?.partnerOrders || []}
              isLoading={
                getPartnerOrdersIsLoading ||
                isLoadingCSVDownload ||
                isRefetchingExcelDownload ||
                isLoadingExcelDownload
              }
              className=''
              columns={orderTableColumn || []}
              allowRowSelection={true}
              isSorted={true}
              isManualSortBy={false}
              hasFooter={true}
              hasCheckBox={true}
              isHeader={true}
              isFilterEnabled={false}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}
              totalRowCount={tableData?.totalCount || 0}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              forceSelectedRowIndexes={selectedRowIndexes}
              onRowSelect={(ids: any) => {
                setSelectedRowsIds(ids)
              }}
              onDeselectRowId={(e: any) => {
                setDeselectIds(e)
              }}
              onSelectAllRows={(select: any) => {
                setDeselectIds(!select ? 'All' : null)
              }}
            />
          </div>
        </div>
      </div>

      {/* for csv export */}
      <CSVLink filename='order-list.csv' data={csvOrderData} ref={csvLinkEl} />

      <CustomizeColumModal
        handleCustomizeCol={(col: any) => {
          setOrderTableColumn(col)
        }}
        columns={orderTableColumn}
        handleCloseModal={() => {
          setIsOpenCustomizeModal(false)
        }}
        section='Order'
        isModalOpen={isOpenCustomizeModal}
        resetCustomizesColumns={resetCustomizesColumns}
        localStorageKey='orderTableColumn'
      />
    </>
  )
}

OrderList.defaultProps = {
  className: undefined,
}

export default OrderList
